import {axios} from '../client';

import {
  SST_API_PATH_MACHINE_CENTERS,
} from '../../Constants'

export const getControlPoint = ({queryKey}) => {
  const [, {machineCenterId, controlPointId}] = queryKey;
  return axios.get(`${SST_API_PATH_MACHINE_CENTERS}/${machineCenterId}/control-points/${controlPointId}`).then(res => res.data);
}

export const getControlPoints = ({queryKey}) => {
  const [, {machineCenterId}] = queryKey;
  return axios.get(`${SST_API_PATH_MACHINE_CENTERS}/${machineCenterId}/control-points`).then(res => res.data);
}

export const createControlPoint = (controlPoint) => {
  return axios.post(`${SST_API_PATH_MACHINE_CENTERS}/${controlPoint.machineCenterId}/control-points`, controlPoint).then(res => res.data);
}

export const updateControlPoint = (controlPoint) => {
  return axios.put(`${SST_API_PATH_MACHINE_CENTERS}/${controlPoint.machineCenterId}/control-points/${controlPoint.id}`, controlPoint).then(res => res.data);
}