import React, { useContext, useEffect, useMemo, useState } from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query';
import { useHistory, useLocation } from 'react-router-dom'

import CreateEditWrapper from "../../shared/components/CreateEdit/CreateEditWrapper";
import {useSnackbar} from 'notistack';
import { MenuItem, TextField } from '@mui/material'
import {makeStyles} from '@mui/styles';
import queryString from "query-string";
import {SiteContext} from "../../Context";
import SSTDropdown, { NO_CONTENT_ITEM_VALUE } from '../../shared/components/SSTDropdown'
import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_INSERT } from "../../shared/Utilities";

import {
  getCustomer,
  getCustomerLines, getCustomerMachineCenters,
  getCustomerPlants,
  getEntityPermissions,
} from '../../query/queries'
import {
  SST_PAGE_CREATE_CONTROL_POINT,
  SST_PAGE_LIST_CONTROL_POINTS,
} from '../../Constants'
import translations from '../../translations/en.json';
import QuantifeelSwitch from '../../shared/components/QuantifeelSwitch'
import { createControlPoint, getControlPoint, updateControlPoint } from '../../query/entities/controlPoints'

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_INSERT, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const emptyControlPoint = {};

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  content: {
    width: '500px',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formControl: {
    marginBottom: '30px',
    fontSize: '20px'
  }
})

const CreateAndEditControlPoint = () => {

  const {setBreadcrumbs, currentCustomer, hasPermission} = useContext(SiteContext);
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [controlPoint, setControlPoint] = useState(emptyControlPoint);
  const [isEdit, setIsEdit] = useState(false);
  const [hasFormInitialized, setHasFormInitialized] = useState(false); // Used to prevent form from re-initializing...

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const memoizedEmptyArrayDefaultValue = useMemo(() => [], []);

  const {isLoading: isLoadingControlPointData, data: controlPointData} = useQuery(
    ['controlPoint', {machineCenterId: controlPoint.machineCenterId, controlPointId: controlPoint.id}],
    getControlPoint,
    {enabled: !!controlPoint.machineCenterId && !!controlPoint.id}
  );

  const {isLoading: isLoadingCustomer, data: customer = []} = useQuery(
    ['customers', {customerId: currentCustomer}],
    getCustomer
  );

  // ------------
  // -- Plants --
  // ------------

  // Get plants, including deleted or inactive; for purposes of populating Plants dropdown, when editing a control point...
  const {isLoading: isLoadingPlantsIncludeDeletedOrInactive, data: plantsIncludeDeletedOrInactive= memoizedEmptyArrayDefaultValue} = useQuery(
    ['plants', {customerId: currentCustomer}, { includeDeleted: true }],
    getCustomerPlants,
  );

  const hasPlantsIncludeDeletedOrInactiveResponded = useMemo(() => {
    return plantsIncludeDeletedOrInactive !== memoizedEmptyArrayDefaultValue;
  }, [plantsIncludeDeletedOrInactive]); // eslint-disable-line react-hooks/exhaustive-deps

  // Derive not deleted or active plants, for purposes of populated Plants dropdown, when creating a control point...
  const plantsOmitDeletedIncludeInactive = useMemo(() => {
    return plantsIncludeDeletedOrInactive.filter((plant) => !plant.isDeleted);
  }, [plantsIncludeDeletedOrInactive]);

  // -----------
  // -- Lines --
  // -----------

  // Get customer lines, including deleted lines or inactive; for purposes of populating Lines dropdown, when creating a control point...
  const {isLoading: isLoadingLinesIncludeDeletedOrInactive, data: linesIncludeDeletedOrInactive= memoizedEmptyArrayDefaultValue} = useQuery(
    ['lines', {customerId: currentCustomer}, { includeDeleted: true }],
    getCustomerLines,
  );

  const hasLinesIncludeDeletedOrInactiveResponded = useMemo(() => {
    return linesIncludeDeletedOrInactive !== memoizedEmptyArrayDefaultValue;
  }, [linesIncludeDeletedOrInactive]); // eslint-disable-line react-hooks/exhaustive-deps

  // Derive not deleted or active lines, for purposes of populating Lines dropdown, when creating a control point...
  const linesOmitDeletedIncludeInactive = useMemo(() => {
    return linesIncludeDeletedOrInactive.filter((line) => !line.isDeleted);
  }, [linesIncludeDeletedOrInactive]);

  // ---------------------
  // -- Machine Centers --
  // ---------------------

  // Get machine centers, including inactive; for purposes of populating Machine Centers dropdown, when editing a control point...
  const {isLoading: isLoadingMachineCentersIncludeInactive, data: machineCentersIncludeInactive = memoizedEmptyArrayDefaultValue} = useQuery(
    ['machineCenters', {customerId: currentCustomer}],
    getCustomerMachineCenters,
  );

  const hasMachineCentersIncludeInactiveResponded = useMemo(() => {
    return machineCentersIncludeInactive !== memoizedEmptyArrayDefaultValue;
  }, [machineCentersIncludeInactive]); // eslint-disable-line react-hooks/exhaustive-deps

  // ----------
  // -- Misc --
  // ----------

  const {isLoading: isLoadingPermissions, data: permissions} = useQuery(
    ['permissions', currentCustomer, 'controlPoint', {ids: [controlPoint.id]}], // Review...
    getEntityPermissions,
    {enabled: !!controlPoint.id}
  );

  const readOnly =
    isEdit ?
      !permissions?.controlpoint[controlPoint.id]?.update : // Determine if user has permission to update the given machine center...
      false;

  const pageTitle =
    location.pathname === `/${SST_PAGE_CREATE_CONTROL_POINT}` ?
      translations.common.controlPoints.createControlPoint :
      translations.common.controlPoints.editControlPoint;

  useEffect(()=>{
    if(controlPointData){
      setControlPoint(controlPoint => {return {...controlPoint, ...controlPointData}}); // Merge; since lineId, plantId are not included in controlPointData...
    }
  }, [controlPointData]);

  const {mutate: mutateUpdate, isLoading: isUpdating} = useMutation(updateControlPoint, {
    onSuccess: (data) => {
      enqueueSnackbar(translations.pages.createAndEditControlPoint.controlPointUpdated, {variant: 'success'});
      queryClient.removeQueries('controlPoints');
      queryClient.setQueryData(['controlPoint', {machineCenterId: data.machineCenterId, controlPointId: data.id}], data);
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  });

  const {mutate: mutateCreate, isLoading: isCreating} = useMutation(createControlPoint, {
    onSuccess: (data) => {
      enqueueSnackbar(translations.pages.createAndEditControlPoint.controlPointCreated, {variant: 'success'})
      queryClient.removeQueries('controlPoints');
      queryClient.setQueryData(['controlPoint', {machineCenterId: data.machineCenterId, controlPointId: data.id}], data);
      history.replace(`/${SST_PAGE_LIST_CONTROL_POINTS}`);
    },
    onError: ({response: {data}}) => {
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  });

  const isLoading = useMemo(() => {
    return isLoadingControlPointData ||
           isLoadingCustomer ||
           isLoadingPlantsIncludeDeletedOrInactive ||
           isLoadingLinesIncludeDeletedOrInactive ||
           isLoadingMachineCentersIncludeInactive ||
           isLoadingPermissions ||
           isUpdating ||
           isCreating;
  }, [
    isLoadingControlPointData,
    isLoadingCustomer,
    isLoadingPlantsIncludeDeletedOrInactive,
    isLoadingLinesIncludeDeletedOrInactive,
    isLoadingMachineCentersIncludeInactive,
    isLoadingPermissions,
    isUpdating,
    isCreating
  ]);

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  useEffect(() => {

    // If the form has already initialized; don't initialize it again, since that would reset dropdown
    // selections. Without this check, if any of the deps change we're at risk of resetting the form
    // (e.g. query cache time expires, component refreshes, query fetches data and triggers this useEffect)
    if (hasFormInitialized) {
      return;
    }

    // Validate that source queries of deps have responded...
    if ( !hasPlantsIncludeDeletedOrInactiveResponded || // Associated w/ plantsIncludeDeletedOrInactive dependency...
         !hasLinesIncludeDeletedOrInactiveResponded || // Associated w/ linesIncludeDeletedOrInactive dependency...
         !hasMachineCentersIncludeInactiveResponded ) { // Associated w/ machineCentersIncludeInactive dependency...
      return;
    }

    // Parse query string...
    const qs = queryString.parse(location.search);

    // Handle...
    if (qs.controlPointId) { // If we get passed in a controlPointId, we are EDITING a Control Point...

      document.title = translations.common.controlPoints.editControlPoint;
      setIsEdit(true);
      setBreadcrumbs([{title: translations.common.controlPoints.editControlPoint}]);

      if (qs.machineCenterId) {
        let queryParamMachineCenter = machineCentersIncludeInactive?.find(machineCenter => machineCenter.id === qs.machineCenterId);
        setControlPoint(controlPoint => { return { ...controlPoint, plantId: queryParamMachineCenter.plantId, lineId: queryParamMachineCenter.lineId, machineCenterId: queryParamMachineCenter.id, id: qs.controlPointId }});
      }

    } else { // We are CREATING a Control Point...

      document.title = translations.common.controlPoints.createControlPoint;
      setIsEdit(false);
      setBreadcrumbs([{title: translations.common.controlPoints.createControlPoint}]);

      // If we get passed machineCenterId, lineId, or plantId, set control point attrs accordingly...
      if (qs.machineCenterId) {
        let queryParamMachineCenter = machineCentersIncludeInactive?.find(machineCenter => machineCenter.id === qs.machineCenterId);
        if (queryParamMachineCenter) {
          setControlPoint(controlPoint => {return {...controlPoint, plantId: queryParamMachineCenter.plantId, lineId: queryParamMachineCenter.lineId, machineCenterId: queryParamMachineCenter.id}});
        }
      } else if (qs.lineId) {
        let queryParamLine = linesOmitDeletedIncludeInactive?.find(line => line.id === qs.lineId);
        if (queryParamLine) {
          setControlPoint(controlPoint => {return {...controlPoint, plantId: queryParamLine.plantId, lineId: queryParamLine.id}});
        }
      } else if (qs.plantId) {
        let queryParamPlant = plantsOmitDeletedIncludeInactive?.find(plant => plant.id === qs.plantId);
        if (queryParamPlant) {
          setControlPoint(controlPoint => {return {...controlPoint, plantId: queryParamPlant.id}});
        }
      }

    }

    setHasFormInitialized(true);

  }, [setBreadcrumbs, location.search, machineCentersIncludeInactive, linesOmitDeletedIncludeInactive, plantsOmitDeletedIncludeInactive]); // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------
  // -- END useEffects --
  // --------------------

  const submit = (e) => {
    e.preventDefault();
    if (isEdit) {
      mutateUpdate(controlPoint);
    } else {
      mutateCreate(controlPoint);
    }
  };

  const handlePlantSelected = (e) => {
    const plantId = e.target.value;
    handleUpdate({
      plantId, // Set plantId...
      lineId: null, // Clear lineId, since plantId has changed...
      machineCenterId: null, // Clear machineCenterId, since plantId has changed...
    });
  }

  const handleLineSelected = (e) => {
    const lineId = e.target.value;
    handleUpdate({
      lineId, // Set lineId...
      machineCenterId: null, // Clear machineCenterId, since lineId has changed...
    });
  }

  const handleUpdate = (update) => {
    setControlPoint(cp => ({...cp, ...update}));
  }

  // ------------------
  // -- BEGIN render --
  // ------------------

  const classes = useStyles();

  return (
    <div data-testid={isEdit ? 'edit-control-point-page' : 'create-control-point-page'} className={classes.root}>
      {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
        <CreateEditWrapper
          onBack={() => !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_CONTROL_POINTS}`)}
          buttonTitle={isEdit ? translations.common.save : translations.common.create}
          pageTitle={isEdit ? translations.common.controlPoints.editControlPoint : translations.common.controlPoints.createControlPoint}
          submit={submit}
          isLoading={isLoading}
          classes={classes}
          disableSubmit={readOnly}
          headerContentRight={
            <QuantifeelSwitch
              checked={!controlPoint.inactive}
              onChange={(e) => {handleUpdate({inactive: !e.target.checked})}}
            />
          }>
          <div className={classes.content}>

            {/* Customer */}
            <SSTDropdown
              classes={classes}
              disabled={true}
              isLoadingContents={isLoadingCustomer}
              label={translations.common.customers.customer}
              defaultValue={''}
              setValueFunc={() => {}}
              selectedValueId={currentCustomer || ''}
              mappedList={[customer].map((c) =>
                <MenuItem
                  key={c.name}
                  value={c.id}>
                  {c.name}
                </MenuItem>
              )}
            />

            {/* Plant */}
            <SSTDropdown
              classes={classes}
              disabled={isLoading || isEdit}
              readOnly={readOnly}
              isLoadingContents={isLoadingPlantsIncludeDeletedOrInactive}
              label={translations.common.plants.plant}
              defaultValue={''}
              selectedValueId={controlPoint.plantId || ''}
              setValueFunc={handlePlantSelected}
              mappedList={
                // If isEdit; leverage plantsOmitDeletedIncludeInactive, since we could be editing a Control Point w/ deleted or inactive ancestors; else map plantsOmitDeletedAndInactive...
                ( isEdit === true ? plantsIncludeDeletedOrInactive : plantsOmitDeletedIncludeInactive)
                  .map((plant) =>
                    <MenuItem
                      key={plant.name}
                      value={plant.id}>
                      {plant.name}
                    </MenuItem>
                  )
              }
            />

            {/* Line */}
            <SSTDropdown
              classes={classes}
              disabled={isLoading || isEdit}
              readOnly={readOnly}
              isLoadingContents={isLoadingLinesIncludeDeletedOrInactive}
              label={translations.common.lines.line}
              defaultValue={''}
              selectedValueId={
                controlPoint.lineId === NO_CONTENT_ITEM_VALUE ? // If lineId is 0, as defaulted by SSTDropdown...
                  controlPoint.lineId : // ...then supply controlPoint.lineId of 0
                  controlPoint.lineId || ''
              }
              setValueFunc={handleLineSelected}
              mappedList={
                // If isEdit; leverage linesOmitDeletedIncludeInactive, since we could be editing a Control Point w/ deleted or inactive ancestors; else map linesOmitDeleteOrInactive...
                (isEdit === true ? linesIncludeDeletedOrInactive : linesOmitDeletedIncludeInactive)
                  .filter(line => line.plantId === controlPoint.plantId) // Filter out lines that aren't associated to the selected plant...
                  .map((line) =>
                    <MenuItem
                      key={line.name}
                      value={line.id}>
                      {line.name}
                    </MenuItem>
                  )
              }
            />

            {/* Machine Center */}
            <SSTDropdown
              classes={classes}
              disabled={isLoading || isEdit}
              readOnly={readOnly}
              isLoadingContents={isLoadingMachineCentersIncludeInactive}
              label={translations.common.machineCenters.machineCenter}
              defaultValue={''}
              selectedValueId={
                controlPoint.machineCenterId === NO_CONTENT_ITEM_VALUE ? // If machineCenterId is 0, as defaulted by SSTDropdown...
                  controlPoint.machineCenterId : // ...then supply controlPoint.machineCenterId of 0
                  controlPoint.machineCenterId || ''
              }
              setValueFunc={(e) => handleUpdate({machineCenterId: e.target.value})}
              mappedList={
                machineCentersIncludeInactive
                  .filter(machineCenters => machineCenters.lineId === controlPoint.lineId) // Filter out machine centers that aren't associated to the selected line...
                  .map((machineCenter) =>
                    <MenuItem
                      key={machineCenter.name}
                      value={machineCenter.id}>
                      {machineCenter.name}
                    </MenuItem>
                  )
              }
            />

            {/* Control Point Name */}
            <TextField
              value={controlPoint.name || ''}
              onChange={(e) => {handleUpdate({name: e.target.value})}}
              required
              id="controlPointName"
              name="controlPointName"
              label={translations.pages.createAndEditControlPoint.controlPointName}
              fullWidth
              InputProps={{
                readOnly: readOnly
              }}
              disabled={isLoading}
            />

          </div>

        </CreateEditWrapper>
      }
    </div>);
};

export default CreateAndEditControlPoint;
