import Administration from '../Administration/Administration'
import CustomerLineDetails from '../CustomerLineDetails/CustomerLineDetails'
import CreateAndEditCustomer from '../Customers/CreateAndEditCustomer'
import ListAllCustomers from '../Customers/ListAllCustomers'
import Dashboard from '../Dashboard/Dashboard'
import ListAllDrones from '../Drones/ListAllDrones'
import ViewDrone from '../Drones/ViewDrone'
import ErrorReportDetails from '../ErrorReports/ErrorReportDetails'
import ListErrorReports from '../ErrorReports/ListReports'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import CreateAndEditHub from '../Hub/CreateAndEditHub'
import HubLogs from '../Hub/HubLogs'
import ListHubs from '../Hub/ListHubs'
import ListLineConfigurations from '../LineConfigurations/ListLineConfigurations'
import ViewLineConfiguration from '../LineConfigurations/ViewLineConfiguration'
import CreateAndEditLine from '../Lines/CreateAndEditLine'
import LinemapBuilder from '../Lines/LinemapBuilder'
import ListLines from '../Lines/ListLines'
import ViewLine from '../Lines/ViewLine/ViewLine'
import Login from '../Login/Login'
import Logout from '../Logout/Logout'
import SsoLogin from "../Login/Sso/SsoLogin"
import CombinedMultiSensorVibration from '../MultiSensor/CombinedMultiSensorVibration'
import CreateAndEditMultiSensor from '../MultiSensor/CreateAndEditMultiSensor'
import ListMultiSensors from '../MultiSensor/ListMultiSensors'
import CreateAndEditPlant from '../Plants/CreateAndEditPlant'
import ListAllPlants from '../Plants/ListAllPlants'
import ProfilePage from '../ProfilePage/ProfilePage'
import ConditionTrendDetails from '../QuickSight/ConditionTrendDetails'
import ConditionTrendSummary from '../QuickSight/ConditionTrendSummary'
import ListDashboards from '../QuickSight/ListDashboards'
import QuickSightPage from '../QuickSight/QuickSightPage'
import StandardReport from '../Reports/StandardReport'
import ResetPassword from '../ResetPassword/ResetPassword'
import ListRuns from '../Runs/ListRuns'
import RunDetails from '../Runs/RunDetails'
import Support from '../Support/Support'
import CreateAndEditUser from '../User/CreateAndEditUser'
import ListAllUsers from '../User/ListAllUsers'
import Privacy from '../Privacy/Privacy'
import LineSettings from '../Lines/LineSettings/LineSettings'
import ListMachineCenters from '../MachineCenters/ListMachineCenters'
import CreateAndEditMachineCenter from '../MachineCenters/CreateAndEditMachineCenter'
import MachineCenterSettings from '../MachineCenters/MachineCenterSettings/MachineCenterSettings'
import HelpCenter from "../HelpCenter/HelpCenter";
import {
  SST_PAGE_CREATE_CONTROL_POINT,
  SST_PAGE_EDIT_CONTROL_POINT,
  SST_PAGE_LIST_CONTROL_POINTS,
} from '../../Constants'
import ListControlPoints from '../ControlPoints/ListControlPoints'
import CreateAndEditControlPoint from '../ControlPoints/CreateAndEditControlPoint'

const Routes = [
  {
    path: '/',
    component: Login,
    requiresLogin: false,
  },
  {
    path: '/login',
    component: Login,
    requiresLogin: false,
  },
  {
    path: "/sso",
    component: SsoLogin,
    requiresLogin: true
  },
  {
    path: "/privacy",
    component: Privacy,
    requiresLogin: false
  },
  {
    path: "/support",
    component: Support,
    requiresLogin: false
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/dashboard',
    requiresLogin: true,
    component: Dashboard,
    navSection: 'Dashboard',
  },
  {
    path: '/list-dashboards',
    requiresLogin: true,
    component: ListDashboards,
    navSection: 'Reporting',
  },
  {
    path: '/view-dashboard',
    requiresLogin: true,
    component: QuickSightPage,
    navSection: 'Reporting',
  },
  {
    path: '/condition-trend-summary',
    requiresLogin: true,
    component: ConditionTrendSummary,
    navSection: 'Dashboard',
  },
  {
    path: '/condition-trend-details',
    requiresLogin: true,
    component: ConditionTrendDetails,
    navSection: 'Dashboard',
  },
  {
    requiresLogin: true,
    path: '/list-hubs',
    component: ListHubs,
    navSection: 'Hubs',
  },
  {
    requiresLogin: true,
    path: '/create-hub',
    component: CreateAndEditHub,
    navSection: 'Hubs',
  },
  {
    requiresLogin: true,
    path: '/edit-hub',
    component: CreateAndEditHub,
    navSection: 'Hubs',
  },
  {
    requiresLogin: true,
    path: '/hub-logs',
    component: HubLogs,
    navSection: 'Hubs',
  },
  {
    requiresLogin: true,
    path: '/list-customers',
    component: ListAllCustomers,
    navSection: 'Customers',
  },
  {
    requiresLogin: true,
    path: '/create-customer',
    component: CreateAndEditCustomer,
    navSection: 'Customers',
  },
  {
    requiresLogin: true,
    path: '/edit-customer',
    component: CreateAndEditCustomer,
    navSection: 'Customers',
  },

  // Lines...
  {
    requiresLogin: true,
    path: '/list-lines',
    component: ListLines,
    navSection: 'Lines',
  },
  {
    requiresLogin: true,
    path: '/view-line/:lineId',
    component: ViewLine,
    navSection: 'Lines',
  },
  {
    requiresLogin: true,
    path: '/line-settings/:lineId',
    component: LineSettings,
    navSection: 'Lines',
  },
  {
    requiresLogin: true,
    path: '/create-line',
    component: CreateAndEditLine,
    navSection: 'Lines',
  },
  {
    requiresLogin: true,
    path: '/edit-line',
    component: CreateAndEditLine,
    navSection: 'Lines',
  },

  // Machine Centers...
  {
    requiresLogin: true,
    path: '/list-machine-centers',
    component: ListMachineCenters,
    navSection: 'Machine Centers',
  },
  {
    requiresLogin: true,
    path: '/create-machine-center',
    component: CreateAndEditMachineCenter,
    navSection: 'Machine Centers',
  },
  {
    requiresLogin: true,
    path: '/edit-machine-center',
    component: CreateAndEditMachineCenter,
    navSection: 'Machine Centers',
  },
  {
    requiresLogin: true,
    path: '/machine-center-settings/:machineCenterId',
    component: MachineCenterSettings,
    navSection: 'Machine Centers',
  },

  // Control Points...
  {
    requiresLogin: true,
    path: `/${SST_PAGE_LIST_CONTROL_POINTS}`,
    component: ListControlPoints,
    navSection: 'Control Points',
  },
  {
    requiresLogin: true,
    path: `/${SST_PAGE_CREATE_CONTROL_POINT}`,
    component: CreateAndEditControlPoint,
    navSection: 'Control Points',
  },
  {
    requiresLogin: true,
    path: `/${SST_PAGE_EDIT_CONTROL_POINT}`,
    component: CreateAndEditControlPoint,
    navSection: 'Control Points',
  },
  // {
  //   requiresLogin: true,
  //   path: `/${SST_PAGE_CONTROL_POINT_SETTINGS}/:machineCenterId`,
  //   component: ControlPointSettings,
  //   navSection: 'Control Points',
  // },

  {
    requiresLogin: true,
    path: '/list-runs',
    component: ListRuns,
    navSection: 'Lines',
  },
  {
    requiresLogin: true,
    path: '/list-line-configurations',
    component: ListLineConfigurations,
    navSection: 'Lines',
  },
  {
    requiresLogin: true,
    path: '/lines/:lineId/configurations/:lineConfigurationId',
    component: ViewLineConfiguration,
    navSection: 'Lines',
  },
  {
    requiresLogin: true,
    path: '/view-run',
    component: RunDetails,
    navSection: 'Lines',
  },
  {
    requiresLogin: true,
    path: '/users',
    component: ListAllUsers,
    navSection: 'Users',
  },
  {
    requiresLogin: true,
    path: '/create-user',
    component: CreateAndEditUser,
    navSection: 'Users',
  },
  {
    requiresLogin: true,
    path: '/edit-user',
    component: CreateAndEditUser,
    navSection: 'Users',
  },

  {
    requiresLogin: true,
    path: '/create-sensor',
    component: CreateAndEditMultiSensor,
    navSection: 'Sensors',
  },
  {
    requiresLogin: true,
    path: '/edit-sensor',
    component: CreateAndEditMultiSensor,
    navSection: 'Sensors',
  },
  {
    requiresLogin: true,
    path: '/list-sensors',
    component: ListMultiSensors,
    navSection: 'Sensors',
  },
  {
    path: '/sensors/:sensorId/combined-vibration',
    requiresLogin: true,
    component: CombinedMultiSensorVibration,
    navSection: 'Sensors',
  },
  {
    requiresLogin: true,
    path: '/list-plants',
    component: ListAllPlants,
    navSection: 'Plants',
  },
  {
    requiresLogin: true,
    path: '/create-plant',
    component: CreateAndEditPlant,
    navSection: 'Plants',
  },
  {
    requiresLogin: true,
    path: '/edit-plant',
    component: CreateAndEditPlant,
    navSection: 'Plants',
  },

  {
    requiresLogin: true,
    path: '/list-drones',
    component: ListAllDrones,
    navSection: 'Drones',
  },
  {
    requiresLogin: true,
    path: '/view-drone/:droneId',
    component: ViewDrone,
    navSection: 'Drones',
  },

  {
    requiresLogin: true,
    path: '/list-error-reports',
    component: ListErrorReports,
    navSection: 'Error Reports',
  },
  {
    requiresLogin: true,
    path: '/error-reports/:errorReportId',
    component: ErrorReportDetails,
    navSection: 'Error Reports',
  },

  {
    path: '/user-settings',
    requiresLogin: true,
    component: ProfilePage,
    navSection: 'Administration',
  },
  {
    path: '/customer-line-details/:lineId',
    requiresLogin: true,
    component: CustomerLineDetails,
    navSection: 'Lines',
  },

  {
    requiresLogin: true,
    path: '/standard-report',
    component: StandardReport,
  },
  {
    path: '/logout',
    component: Logout,
    requiresLogin: false,
  },
  {
    requiresLogin: true,
    path: '/administration',
    component: Administration,
    navSection: 'Administration',
  },
  {
    requiresLogin: true,
    path: '/help-center',
    component: HelpCenter,
  },
  // Commented out
  {
    requiresLogin: true,
    path: '/linemap-builder',
    component: LinemapBuilder,
    navSection: 'Administration',
  },
]

export default Routes
