import {axios} from '../client';

import {
  SST_API_PATH_CUSTOMERS
} from './../../Constants';

export const getAllCustomers = ({queryKey}) => {
  const [, , queryParams] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}`, {
    params: queryParams
  }).then(res => res.data);  
}

export const getCustomer = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}`).then(res => res.data);  
}

export const updateCustomer = (customer) => {
  const customerId = customer.id

  if(!customer.customerTypeId){
    delete customer.customerTypeId;
  }

  return axios.put(`${SST_API_PATH_CUSTOMERS}/${customerId}`, customer).then(res => res.data);
}

export const createCustomer = (customer) => {
  const customerCopy = {...customer}
  if(!customerCopy.customerTypeId){
    delete customerCopy.customerTypeId;
  }
  return axios.post(`${SST_API_PATH_CUSTOMERS}`, customerCopy).then(res => res.data);
}

export const getCustomerTypes = () => {
  return axios.get(`${SST_API_PATH_CUSTOMERS}/types`).then(res => res.data);
}
export const getCustomerPlants = ({queryKey}) => {
  const [, {customerId}, queryParams ={}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/plants`, {
    params: queryParams
  })
  .then(res => res.data);
}

export const getCustomerDashboards = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/dashboards`)
  .then(res => res.data);
}

export const getCustomerLines = ({queryKey}) => {
  const [, {customerId}, queryParams={}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/lines`, {
    params: queryParams
  })
  .then(res => res.data);
}

export const getCustomerMachineCenters = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/machine-centers`)
  .then(res => res.data);
}

export const getCustomerControlPoints = ({queryKey}) => {
  const [, {customerId}, queryParams={}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/control-points`, {
    params: queryParams
  })
  .then(res => res.data);
}

export const getCustomerUsers = ({queryKey}) => {
  const [, {customerId}] = queryKey;
  return axios.get(`${SST_API_PATH_CUSTOMERS}/${customerId}/users`)
  .then(res => res.data);
}

export const getCustomerLinesQuery = ({queryKey}) => {
  const [, {customerId}, {lineId}, {sortBy}, {sortOrder}, {pageSize}, {page}, {filter}] = queryKey;
  return axios.request({
    method: 'POST',
    url: `${SST_API_PATH_CUSTOMERS}/${customerId}/lines/${lineId}/runs/$query`,
    data: {
      sortBy: sortBy,
      sortOrder: sortOrder,
      pageSize: pageSize,
      page: page,
      filter: filter
    }
  })

}