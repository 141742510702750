import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {SiteContext} from "../../Context";
import { MenuItem, Typography } from '@mui/material'
import {makeStyles} from '@mui/styles';
import SSTDropdown from "../../shared/components/SSTDropdown";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import {ReactComponent as EditIcon} from '../../img/icons/edit.svg'
import {ReactComponent as SettingsIcon} from '../../img/icons/settings.svg'
import translations from '../../translations/en.json';

import {useQuery} from 'react-query';
import { getCustomerLines, getCustomerPlants } from '../../query/queries'

import '../PageStyles/TablePage.css';

import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE } from "../../shared/Utilities";

import {
  SST_PAGE_EDIT_LINE,
  SST_PAGE_LINE_SETTINGS,
  SST_PAGE_VIEW_LINE,
} from '../../Constants'
import CreateButton from '../../shared/components/CreateButton'
import QuantifeelSvgIcon from '../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon'
import QuantifeelTooltip from '../../shared/components/QuantifeelTooltip'
import { v4 as uuidv4 } from 'uuid';
import ActiveStatusIndicator from "../../shared/components/ActiveStatusIndicator/ActiveStatusIndicator";

const pageTitle = 'List Lines';
const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Plant', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const COLUMN_IDS = {
  LINE: "line",
  PLANT: "plant",
  CONFIG_VERSION: "highestConfigurationVersion",
  ACTIVE_STATUS: "activeStatus",
  ACTIONS: "actions"
};

const columns = [
  {id: COLUMN_IDS.LINE, label: translations.common.lines.line, width: "300px"},
  {id: COLUMN_IDS.PLANT, label: translations.common.plants.plant, width: "300px"},
  {id: COLUMN_IDS.CONFIG_VERSION, label: translations.pages.listLines.configVersion},
  {id: COLUMN_IDS.ACTIVE_STATUS, label: translations.pages.listLines.status},
  {id: COLUMN_IDS.ACTIONS, label: translations.common.actions, disableSort: true}
]

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '350px',
    paddingBottom: theme.spacing(1)
  },
  textOverflowHiddenEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  actionsContainer: {
    '& > *': {
      marginRight: theme.spacing(3)
    }
  }
}));

const ALL_MENU_ITEM = uuidv4();

const ListLines = (props) => {

  const {setBreadcrumbs, currentCustomer, pageFilters, setPageFilters, hasPermission} = useContext(SiteContext);
  const [customer, setCustomer] = useState(currentCustomer);
  const [selectedPlant, setSelectedPlant] = useState(pageFilters?.plant || ALL_MENU_ITEM);
  const [filteredLines, setFilteredLines] = useState([]);
  const [resetTablePagination, setResetTablePagination] = useState(false);

  const {
    isLoading: isLoadingPlants,
    data: plants = []
  } = useQuery(['plants', {customerId: currentCustomer}], getCustomerPlants);

  const {
    isLoading: isLoadingLines,
    data: lines = []
  } = useQuery(['lines', {customerId: customer}, {includePlantName: true}], getCustomerLines);

  useEffect(() => {
    document.title = pageTitle;
    setBreadcrumbs([
      {
        title: pageTitle,
      }
    ]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if(currentCustomer !== customer){
      setSelectedPlant('')
      setCustomer(currentCustomer);
    }
  }, [currentCustomer, customer]);

  useEffect(() => {

    // Validate lines...
    if (lines.length === 0) {
      return;
    }

    // If selectedPlant is ALL_MENU_ITEM...
    if (selectedPlant === ALL_MENU_ITEM) {
      setFilteredLines(lines);
      return;
    }

    // If selectedPlant is not ALL_MENU_ITEM...
    // Filter lines to only include lines from selected plant...
    const filteredLines = lines.filter((line) => { return line.plantId === selectedPlant });
    setFilteredLines(filteredLines);

  }, [selectedPlant, lines]);

  const generateRows = (lines) => {
    return lines.map((line) => {
      return {
        [COLUMN_IDS.LINE]:
          <CopyLabel value={line.id} rawval={line.name.toLowerCase()}>
            <QuantifeelTooltip title={translations.common.lines.viewLine}>
              <Link
                className={classes.textOverflowHiddenEllipsis}
                to={`/${SST_PAGE_VIEW_LINE}/${line.id}`}>
                {line.name}
              </Link>
            </QuantifeelTooltip>
          </CopyLabel>,
        [COLUMN_IDS.PLANT]:
          <CopyLabel value={line.plantId} rawval={line.plantName.toLowerCase()}>
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {line.plantName}
            </Typography>
          </CopyLabel>,
        highestConfigurationVersion: line.highestConfigurationVersion,
        [COLUMN_IDS.ACTIVE_STATUS]:
            <ActiveStatusIndicator
                rawval={line.inactive ? "inactive" : "active"} // To enable sorting...
                isActive={!line.inactive}
            />,
        actions:
          <div className={classes.actionsContainer}>
            <Link to={`/${SST_PAGE_EDIT_LINE}?lineId=${line.id}`}>
              <QuantifeelSvgIcon
                component={EditIcon}
                viewBox="0 0 32 32"
                tooltipTitle={translations.common.lines.editLine}
              />
            </Link>
            <Link to={`/${SST_PAGE_LINE_SETTINGS}/${line.id}`}>
              <QuantifeelSvgIcon
                component={SettingsIcon}
                viewBox="0 0 32 32"
                style={{fill: 'black'}}
                tooltipTitle={translations.common.lines.lineSettings}
              />
            </Link>
          </div>
      }
    });
  }

  const updateSelectedPlant = e => {

    const selectedPlant = e.target.value;

    // Set selected plant...
    setSelectedPlant(selectedPlant);

    // Set pageFilters...
    if (selectedPlant === ALL_MENU_ITEM) {
      setPageFilters(undefined); // Undefined since ALL_MENU_ITEM is not a plant. However, ALL_MENU_ITEM is default selectedPlant if !pageFilters...
    } else {
      setPageFilters(f => ({ ...f, plant: selectedPlant }));
    }

    // Reset table pagination...
    setResetTablePagination(true);
  };

  const onCreateButtonClick = (e) => {
    if(selectedPlant === ALL_MENU_ITEM) {
      props.history.push(`/create-line`);
    } else {
      props.history.push(`/create-line?plantId=${selectedPlant}`);
    }
  };

  const classes = useStyles();

  const getPlantDropdownMenuItems = (plants) => {

    const menuItems = [];

    // Add "All" item...
    menuItems.push(<MenuItem key={ALL_MENU_ITEM} value={ALL_MENU_ITEM}>{"All"}</MenuItem>)

    // Add item for each plant...
    plants.forEach((plant) => {
      menuItems.push(
        <MenuItem key={plant.id} value={plant.id}>{plant.name}</MenuItem>
      )
    })

    return menuItems
  }

  return (
    <div className="page" data-testid="list-lines-page">
      {handlePermissionRedirect(pageTitle, props.history, hasPermission, acceptablePagePermission) &&
        <div className="container">
          <div className="headerContainer">
            <SSTDropdown
              classes={classes}
              disabled={false}
              isLoadingContents={isLoadingPlants}
              label={"Plants"}
              selectedValueId={selectedPlant}
              setValueFunc={updateSelectedPlant}
              fullWidth={false}
              mappedList={getPlantDropdownMenuItems(plants)}
              required={false}
            />
            {hasPermission('Line', 'insert') && <div>
              <CreateButton
                onClick={onCreateButtonClick}
                isLoading={isLoadingLines || isLoadingPlants}
                label={translations.pages.listLines.createLine}
              />
            </div>}
          </div>
          <EnhancedTable
            order={'asc'}
            orderBy={COLUMN_IDS.LINE}
            rows={generateRows(filteredLines)}
            headCells={columns}
            isLoading={isLoadingLines}
            enableFilter
            filterColumns={[COLUMN_IDS.LINE, COLUMN_IDS.PLANT]}
            resetPagination={resetTablePagination}
            onResetPaginationComplete={() => setResetTablePagination(false)}/>
        </div>}
    </div>
  );
};

export default ListLines;
