import React, {createContext, useState} from 'react';
import {LOCAL_STORAGE_KEY_LOGIN_DETAILS,} from "./Constants";

import {setAuthToken} from "./shared/FetchUtilities";
import {setAuthToken as setAuthToken2} from "./query/client";

import EntityPaths from './auth/authorizor';

export const SiteContext = createContext();


const getPermissionsFromRoles = (cr=[]) => {
  const retval = cr?.reduce((m, v) => {
    return [...m, ...v.assignedPermissions];
  }, []) || [];
  return retval;
}

export const SiteContextProvider = props => {

    const loadUserDetails = () => {
        const existingDetails = localStorage.getItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS);

        //If we have a token in local storage, let's try to re-populate
        if (existingDetails != null) {
            return (JSON.parse(existingDetails));
        }

        //This is placeholder JSON for when there is nothing in local storage.
        else {
            return {
                "user":
                    {
                        "firstName": ""
                    },
                "customer":
                    {
                        name: "",
                        id: ""
                    },
                ...props.userDetails
            }
        }
    };

    const [userDetails, setUserDetails] = useState(loadUserDetails() || {});
    const [customerPermissions, setCustomerPermissions] = useState(getPermissionsFromRoles(userDetails?.customerRoles || []));
    const [platformPermissions, setPlatformPermissions] = useState(getPermissionsFromRoles(userDetails?.platformRoles || []));
    const [currentCustomer, setCurrentCustomer] = useState(loadUserDetails()?.customer?.id || {});
    const [userCustomers, setUserCustomers] = useState(loadUserDetails()?.customers || []);
    const [navSection, setNavSection] = useState("");
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);


    const logout = ()  => {
      localStorage.removeItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS);
      setUserDetails(loadUserDetails());
      setCustomerPermissions([]);
      setPlatformPermissions([]);
      setCurrentCustomer("");
    }

    // Ordering here is super important for calls that happen in app.js
    // Token needs to be set in Axios before we set it in userDetails, And
    // The customer needs to be set before we set user details, since
    // Checking for userDetails usually kicks off these operations.
    const login = (data) => {
      setAuthToken(data.token);
      setAuthToken2(data.token);
      localStorage.setItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS, JSON.stringify(data));
      setCurrentCustomer(data.customer.id);
      setUserCustomers(data.customers)
      setCustomerPermissions(getPermissionsFromRoles(data.customerRoles));
      setPlatformPermissions(getPermissionsFromRoles(data.platformRoles));
      setUserDetails(data);

    }

    const saveCurrentCustomer = (customer) => {
      //Update details in local storage
      const userDetails = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS));
      localStorage.setItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS, JSON.stringify({...userDetails, customer}));
      setUserDetails({...userDetails, customer});
      setCurrentCustomer(customer.id);
    };

    const setCustomerRoles = (customerRoles) => {
      //Update Local Storage
      const userDetails = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS));
      localStorage.setItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS, JSON.stringify({...userDetails, customerRoles}));
      setUserDetails({...userDetails, customerRoles});
      setCustomerPermissions(getPermissionsFromRoles(customerRoles));
    }

    const hasPermission = (entity, method, access) => {
      const permissions = customerPermissions.concat(platformPermissions)
      //Check for specified entity
      const hasDirectAccess = permissions.some(p => {
        if(p.method.toLowerCase() === method.toLowerCase() && p.entity.toLowerCase() === entity.toLowerCase()){
          if(access){
            return p.modifier.toLowerCase() === access?.toLowerCase()
          }
          return true
        }
        return false
      });

      if(hasDirectAccess){
        return !!hasDirectAccess;
      }

      //If we're looking for something with a access of platform, it can't
      //be implied by parent permissions
      if(access === "Platform"){
        return false;
      }

      //If we made it this far, we need to start checking the parents
      const parents = EntityPaths[entity];

      return parents?.some(e => {
        return permissions?.some(p => {
          return p.entity.toLowerCase() === e.toLowerCase() &&
                 p.method.toLowerCase() === method.toLowerCase() &&
                 p.modifier === 'children'
        })

      })
    }

    const checkPermission = (item, authFn) => {
        if (!item?.access?.entity || !item?.access?.method) {
            return true
        } else {
            return authFn(item.access.entity, item.access.method, item.access.access)
        }
    }

    const setUserDefaultCustomer = (customerId) => {
      userDetails.user.customerId = customerId;
      localStorage.setItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS, JSON.stringify(userDetails));
      setUserDetails(u => ({...u, user: {...u.user, customerId}}));

    }

    const addCustomer = (c) => {
      const newCustomerList = [...userCustomers, c],
            updatedUserDetails = {...userDetails, customers: newCustomerList};
      localStorage.setItem(LOCAL_STORAGE_KEY_LOGIN_DETAILS, JSON.stringify(updatedUserDetails));
      setUserDetails(updatedUserDetails);
      setUserCustomers(newCustomerList);
    }

    /**
     * Reset SiteContext values that are dependent on the Customer.
     *
     * Use cases:
     * - When switching Customers, reset values that are dependent on the Customer,
     *   so we're not passing values associated to the previous Customer
     */
    const resetCustomerDependentValues = () => {
      // Reset plant, line...
      setPageFilters(f => ({...f, plant: null, line: null, machineCenter: null}));
    }

    return (
        <SiteContext.Provider value={{
            userDetails: userDetails,
            logout: logout,
            apiToken: userDetails.token,
            setUserDetails: setUserDetails,
            setBreadcrumbs: setBreadcrumbs,
            breadcrumbs: breadcrumbs,
            pageFilters: pageFilters,
            setPageFilters: setPageFilters,
            currentCustomer: currentCustomer,
            setCurrentCustomer: saveCurrentCustomer,
            navSection,
            setNavSection,
            login: login,
            hasPermission,
            checkPermission,
            onPlatformCustomer: (userDetails?.customer && (userDetails.customer.id === userDetails.customer.parentCustomerId)),
            setCustomerRoles,
            userCustomers,
            setUserDefaultCustomer,
            addCustomer,
            resetCustomerDependentValues
          }}>
            {props.children}
        </SiteContext.Provider>
    );
};
